<template>
  <b-row>
    <b-col xl="12" lg="12" md="12" sm="12" cols="12">
      <b-card class="card-custom h-100" footer-tag="footer" footer-class="text-right">
        <b-form-group class="text-muted">
          <h4 class="font-weight-bold text-dark ml-8 mb-5">Contract Detail</h4>

          <b-row>
            <b-col cols="12">
              <b-card-body>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                    <b-alert variant="success" dismissible fade :show="show" v-on:dismissed="show = false">
                      {{ response }}
                    </b-alert>
                    <b-alert variant="danger" dismissible fade :show="showError" v-on:dismissed="showError = false">
                      {{ response_error }}
                    </b-alert>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-10">
                    <label class="text-muted">Headline</label>
                    <b-form-input v-model="headline"></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-10">
                    <label class="text-muted">Page Name</label><br />
                    <b-form-select v-on:change="onChange" v-model="relatedPage">
                      <option v-for="(page, i) in pageName" v-bind:key="i" :value="page._id">
                        {{ page.value }}
                      </option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
                    <label class="text-muted">Description</label>
                    <ckeditor v-model="description"></ckeditor></b-col
                ></b-row>
              </b-card-body>
            </b-col>
          </b-row>
        </b-form-group>
        <template #footer>
          <b-button variant="primary" class="font-weight-bold px-10" v-on:click="saveContract">
            Save
          </b-button>
        </template>
      </b-card>
    </b-col></b-row
  >
</template>

<script>
import CKEditor from 'ckeditor4-vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'contracts-new',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Contracts' }])
    this.getPage()
  },
  data() {
    return {
      headline: '',
      description: '',
      relatedPage: '',
      pageName: [],
      show: false,
      response: '',
      showError: false,
      response_error: '',
    }
  },
  components: {
    ckeditor: CKEditor.component,
  },
  methods: {
    saveContract() {
      if (this.headline === '' || this.description === '' || this.relatedPage === '') {
        this.response_error = 'Fill in all fields.'
        this.showError = 3
      } else {
        ApiService.setHeader()
        ApiService.post('contract', {
          value: this.headline,
          description: this.description,
          relatedPage: this.relatedPage,
        }).then(() => {
          this.response = 'Success'
          this.show = 3
        })
      }
    },
    getPage() {
      ApiService.setHeader()
      ApiService.get('page').then(({ data }) => {
        this.pageName = data
      })
    },
    onChange(item) {
      this.relatedPage = item
    },
  },
}
</script>
